import { Typography } from 'antd';
import { GetTrademarkQuery } from 'generated/graphql';
import Card from 'ui/card';
import Flex from 'ui/flex';
import IsLoading from 'ui/isLoading';
import Spacer from 'ui/spacer';
import styles from './trademark.module.scss';

type TrademarkClasses = NonNullable<
  GetTrademarkQuery['trademark_by_pk']
>['trademarkClasses'];

const ProductAndServices = ({
  classes,
  loading,
}: {
  classes: TrademarkClasses;
  loading: boolean;
}) => {
  const displayClasses = () => {
    const sortedClasses = [...classes];
    // Assuming that classCode is a always number
    sortedClasses.sort((a, b) => +(a?.classCode ?? 0) - +(b?.classCode ?? 0));

    return sortedClasses.map((classe) => {
      return (
        <div key={classe?.classCode}>
          <Spacer>
            <span className={styles.numberTag}>{classe?.classCode}</span>
            <Typography.Text className={styles.classDetail}>
              {classe?.classDetail}
            </Typography.Text>
          </Spacer>
        </div>
      );
    });
  };

  return (
    <Card className={styles.cardContainer}>
      <Spacer size="small" direction="vertical">
        <IsLoading loading={loading}>
          <Flex column>
            <Spacer direction="vertical">{displayClasses()}</Spacer>
          </Flex>
        </IsLoading>
      </Spacer>
    </Card>
  );
};

export default ProductAndServices;
