import ConnectedPage from 'business/user/helpers/connectedPage';
import Spacer from 'ui/spacer';
import { Input, Select, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { useOsrFilters } from 'business/resources/osr/services/useOsrFilters';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import NotFoundPage from 'ui/notFoundPage';
import Flex from 'ui/flex';
import { useGetCountriesListQuery } from 'generated/graphql';

import useGetOsrTabs from 'business/resources/osr/services/useGetOsrTabs';

const OsrPage = () => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();
  const { filters, actions } = useOsrFilters();
  const osrtabs = useGetOsrTabs(filters, actions);
  const { data } = useGetCountriesListQuery();
  const countries = data?.country ?? [];

  if (!user.osrAccess) {
    return <NotFoundPage />;
  }

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <ConnectedPage translationKey="osr">
      <Spacer direction="vertical" size="middle">
        <Spacer size="small" direction="vertical">
          <Flex justify="space-between">
            <h1 className="headerTitle">
              {t('pages.osr.title', { context: 'design' })}
            </h1>
          </Flex>

          <div style={{ width: '33%' }}>
            <Spacer direction="vertical" size="xsmall">
              <Input
                defaultValue={filters.companyRef}
                placeholder={t('pages.osr.filters.clientRef')}
                onChange={(e) => actions.onChangeCompanyRef(e.target.value)}
              />
            </Spacer>
          </div>
          <Flex>
            <Select
              showSearch
              mode="multiple"
              style={{ width: '33%' }}
              placeholder="Filtrer par pays"
              optionFilterProp="children"
              onChange={(value) => actions.onChangeCountries(value)}
              filterOption={filterOption}
              options={countries.map((c) => {
                return { label: c.labelFr, value: c.code };
              })}
            />
          </Flex>
          <div>
            <Tabs type="card" items={osrtabs} />
          </div>
        </Spacer>
      </Spacer>
    </ConnectedPage>
  );
};

export default OsrPage;
