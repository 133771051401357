import {
  useGetDesignImagesByIdsQuery,
  useGetDesignImagesListQuery,
} from 'generated/graphql';
import { isDefined } from 'technical/validation';

export const useGetDesignImages = (ids: string[]) => {
  const { data, loading } = useGetDesignImagesListQuery({
    fetchPolicy: 'no-cache',
    variables: { ids },
    skip: !ids.length,
  });

  return {
    imageList:
      data?.getDesignImagesByFamilyIds?.filter((d) => isDefined(d.image)) ?? [],
    loading,
  };
};

export const useGetDesignImagesByIds = (ids: number[]) => {
  const { data, loading } = useGetDesignImagesByIdsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      ids,
    },
    skip: !ids.length,
  });
  return {
    imageList:
      data?.getImagesByDesignIds.filter((i) => isDefined(i.image)) ?? [],
    loading,
  };
};
