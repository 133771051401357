import { notification } from 'antd';
import { UploadFile } from 'antd/lib/upload';
import {
  useGetTrademarkAfuByTrademarkIdQuery,
  useGetTrademarkAfuDownloadUrlLazyQuery,
  useGetTrademarkAfuSignedUrlLazyQuery,
  useUploadAfuDocumentMutation,
} from 'generated/graphql';
import { downloadFile, uploadObjectAsync } from 'technical/fileManagement';
import logger from 'technical/logger';
import { openInNewTab } from 'technical/router/helpers';
import { isDefined } from 'technical/validation';

interface VerifiedUploadFile extends UploadFile {
  originFileObj: NonNullable<UploadFile['originFileObj']>;
  type: NonNullable<UploadFile['type']>;
  size: NonNullable<UploadFile['size']>;
}

function isValidFile(file: UploadFile): file is VerifiedUploadFile {
  return file.originFileObj !== undefined && file.size !== undefined;
}

export const useUploadTrademarkAfu = () => {
  const [getSignedUrl] = useGetTrademarkAfuSignedUrlLazyQuery();
  const [uploadAfuMutation] = useUploadAfuDocumentMutation();
  const uploadAfu = async (files: {
    file: UploadFile[];
    trademarkId: number;
  }) => {
    const uploadedFiles = await Promise.all(
      files.file.map(async (file) => {
        try {
          if (!isValidFile(file)) {
            throw new Error('err');
          }
          const { data } = await getSignedUrl({
            fetchPolicy: 'no-cache',
            variables: {
              fileName: file.name,
            },
          });
          const signedUrl = data?.getTrademarkAfuBucketSignedUrl;
          await uploadObjectAsync(
            file.originFileObj,
            signedUrl?.bucketSignedUrl ?? '',
          );
          return {
            title: file.name,
            filePath: signedUrl?.filePath ?? '',
            trademarkId: files.trademarkId,
          };
        } catch (err) {
          logger.error({
            message: 'error',
            err,
          });
          notification.error({
            message: `Une erreur est survenu à sauvegarder le fichier ${file.name}`,
            description:
              'Veuillez réessayer ou contacter votre responsable BDL',
          });
        }
      }),
    );
    await uploadAfuMutation({
      fetchPolicy: 'no-cache',
      variables: {
        data: uploadedFiles.filter(isDefined),
      },
    });
    notification.success({
      message: 'Affidavit ajouté',
      description: 'Votre responsable BDL va être notifié de son ajout',
    });
  };
  return {
    uploadAfu,
  };
};

export const useGetTrademarkAfu = (trademarkId: number) => {
  const { data, error, loading, refetch } =
    useGetTrademarkAfuByTrademarkIdQuery({
      variables: {
        trademarkId,
      },
    });

  return {
    error,
    loading,
    trademarkAfu: data?.getTrademarkAfu ?? [],
    refetch,
  };
};

export const useDownloadAfu = () => {
  const [download] = useGetTrademarkAfuDownloadUrlLazyQuery();

  const openAfu = async (bucketPathId: string) => {
    try {
      const data = await download({
        fetchPolicy: 'no-cache',
        variables: {
          id: bucketPathId,
        },
      });
      openInNewTab(data.data?.downloadTrademarkAfu?.downloadUrl ?? '');
    } catch (err) {
      notification.error({
        message: 'Erreur',
      });
      logger.error({
        message: 'err',
        err,
      });
    }
  };

  const downloadAfu = async (bucketPathId: string, fileName: string) => {
    try {
      const data = await download({
        fetchPolicy: 'no-cache',
        variables: {
          id: bucketPathId,
        },
      });

      const blob = await (
        await fetch(data.data?.downloadTrademarkAfu?.downloadUrl ?? '')
      ).blob();
      return downloadFile(blob, fileName);
    } catch (err) {
      notification.error({
        message: 'Erreur',
      });
      logger.error({
        message: 'err',
        err,
      });
    }
  };

  const downloadSelectedRows = async (
    selectedRows: { bucketPathId: string; fileName: string }[],
  ) => {
    await Promise.all(
      selectedRows.map((s) => {
        return downloadAfu(s.bucketPathId, s.fileName);
      }),
    );
  };

  return {
    openAfu,
    downloadAfu,
    downloadSelectedRows,
  };
};
