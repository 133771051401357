import { Tabs } from 'antd';
import {
  AdvancedFilters,
  ResourcesData,
} from 'business/advancedSearch/services/types';
import getTabLabel from 'ui/tabs/helper';
import { TabsFamily, isTabsFamily } from 'business/common/services/types';
import { TabsContent } from 'ui/tabs/types';
import { advancedSearchDomainColumns } from 'business/resources/domain/components/columns';
import { patentsColumnsAdvancedSearchTable } from 'business/resources/patent/services/constant/columns';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import { useMemo } from 'react';
import AdvancedFiltersButton, {
  getFilterNumber,
} from 'business/advancedSearch/components/advancedFiltersButton';
import getAllResultsTableColumns from 'business/advancedSearch/components/allResultsTable/columns';
import Table from 'ui/table';
import { advancedSearchTrademarkColumns } from 'business/resources/trademark/components/columns.constant';
import {
  bdlUserDesignAdvancedColumns,
  designAdvancedColumns,
} from 'business/resources/design/components/columns';
import { useDownloadTableToXlsx } from 'business/common/hooks/useDownloadTableToXlsx';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { isBdlAdmin, isBdlUser } from 'business/admin/services/permission';

interface AdvancedSearchTabsProps {
  family: TabsFamily;
  onClickTabFamily: (family: TabsFamily) => void;
  onClickAdvancedFilter: () => void;
  data: ResourcesData;
  filters: AdvancedFilters;
}
const AdvancedSearchTabs = ({
  family,
  onClickTabFamily,
  onClickAdvancedFilter,
  data,
  filters,
}: AdvancedSearchTabsProps) => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();
  const { onExport, loading } = useDownloadTableToXlsx();

  const advancedFiltersButton = useMemo(
    () => (
      <AdvancedFiltersButton
        onClickAdvancedFilter={onClickAdvancedFilter}
        filterNumber={getFilterNumber(filters[family]?.filters)}
      />
    ),
    [family, filters],
  );

  const tabs: TabsContent = [
    {
      key: 'patent',
      label: getTabLabel('patent', t)(data.patent),
      children: (
        <Table
          id="advancedSearchPatentFamilyTable"
          loadingExport={loading}
          defaultColumns={patentsColumnsAdvancedSearchTable}
          searchBarEnabled={false}
          showOwnCheckbox
          {...data.patent.tableProps}
          onExport={(columns) => {
            onExport(columns, 'patent', true);
          }}
          dataSource={data.patent.data}
          customAction={advancedFiltersButton}
        />
      ),
    },
    {
      key: 'design',
      label: getTabLabel('design', t)(data.design),
      children: (
        <Table
          id="advancedSearchDesignFamilyTable"
          defaultColumns={
            isBdlAdmin(user) || isBdlUser(user)
              ? bdlUserDesignAdvancedColumns
              : designAdvancedColumns
          }
          searchBarEnabled={false}
          showOwnCheckbox
          loadingExport={loading}
          onExport={(columns) => {
            onExport(columns, 'design', true);
          }}
          {...data.design.tableProps}
          dataSource={data.design.data}
          customAction={advancedFiltersButton}
        />
      ),
    },
    {
      key: 'trademark',
      label: getTabLabel('trademark', t)(data.trademark),
      children: (
        <Table
          id="advancedSearchTrademarkFamilyTable"
          defaultColumns={advancedSearchTrademarkColumns}
          searchBarEnabled={false}
          showOwnCheckbox
          {...data.trademark.tableProps}
          dataSource={data.trademark.data}
          customAction={advancedFiltersButton}
          loadingExport={loading}
          onExport={(columns) => {
            onExport(columns, 'trademark', true);
          }}
        />
      ),
    },
    {
      key: 'domain',
      label: getTabLabel('domain', t)(data.domain),
      children: (
        <Table
          id="advancedSearchDomainFamilyTable"
          defaultColumns={advancedSearchDomainColumns}
          searchBarEnabled={false}
          {...data.domain.tableProps}
          showOwnCheckbox
          dataSource={data.domain.data}
          customAction={advancedFiltersButton}
          loadingExport={loading}
          onExport={(columns) => {
            onExport(columns, 'domain', true);
          }}
        />
      ),
    },
    {
      key: 'all',
      label: getTabLabel('all', t)(data.all),
      children: (
        <Table
          id="advancedSearchAllResourcesTable"
          searchBarEnabled={false}
          dataSource={data.all.data}
          {...data.all.tableProps}
          showOwnCheckbox
          defaultColumns={getAllResultsTableColumns(t)}
          customAction={advancedFiltersButton}
          loadingExport={loading}
          onExport={(columns) => {
            onExport(columns, 'all', true);
          }}
        />
      ),
    },
  ];

  const onClickTab = (tabClicked: string) => {
    if (isTabsFamily(tabClicked)) {
      onClickTabFamily(tabClicked);
    }
  };

  return (
    <Spacer direction="vertical" size="large">
      <Tabs
        defaultActiveKey={family}
        activeKey={family}
        type="card"
        items={tabs}
        onTabClick={onClickTab}
      />
    </Spacer>
  );
};

export default AdvancedSearchTabs;
