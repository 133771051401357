import { Checkbox } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  useDownloadPatentFamilies,
  useGetPatentFamilies,
} from 'business/resources/patent/services/patentFamilies.service';
import useTableColumn from 'technical/table/useTableColumn';
import { useState } from 'react';
import SearchBar from 'ui/v2/tableActions/search';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import Spacer from 'ui/spacer';
import TagResults from 'ui/tag/tagResults';
import Button from 'ui/button';
import Routes from 'business/router/routes';
import Table from 'ui/v2/table/table';
import patentColumns from './constant';

const PatentFamiliesTable = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [isEffective, setIsEffective] = useState(true);

  const columns = patentColumns(t).patentFamilies;
  const patentFamiliesColumns = useTableColumn(columns);

  const { download, loading: loadingExport } = useDownloadPatentFamilies(
    columns,
    patentFamiliesColumns.visibleColumns,
    {
      isEffective,
      search,
    },
  );

  const { data, loading, total } = useGetPatentFamilies({
    isEffective,
    search,
    limit: patentFamiliesColumns.paginationState.pageSize,
    offset:
      (patentFamiliesColumns.paginationState.current - 1) *
      patentFamiliesColumns.paginationState.pageSize,
    orderBy: patentFamiliesColumns.orderByState,
  });

  return (
    <Table
      columns={patentFamiliesColumns.tableColumns}
      dataTestId="patentFamiliesTable"
      dataSource={data ?? []}
      onChange={patentFamiliesColumns.handlePagination}
      loading={loading}
      pagination={{
        current: patentFamiliesColumns.paginationState.current,
        pageSize: patentFamiliesColumns.paginationState.pageSize,
        total,
      }}
      actions={
        <Spacer align="center" size="small">
          <TagResults total={total} dataTestId="patentTotal" />
          <SearchBar value={search} onDebouncedChange={(e) => setSearch(e)} />
          <Link
            to={{
              pathname: Routes.Search,
              search: `?family=patent&filtersOpen=true`,
            }}
          >
            <Button
              data-test-id="filterButton"
              className="searchButton"
              type="primary"
            >
              {t('table.common.advancedSearch')}
            </Button>
          </Link>
          <Button
            loading={loadingExport}
            data-test-id="exportButton"
            type="primary"
            onClick={() => download()}
          >
            {t('table.common.exportButton')}
          </Button>
          <EditColumnsButton
            initialColumns={columns}
            visibleColumns={patentFamiliesColumns.visibleColumns}
            onChange={patentFamiliesColumns.toggleColumnVisibility}
          />
          <Checkbox
            checked={isEffective}
            onChange={(e) => setIsEffective(e.target.checked)}
          >
            {t('table.common.effective')}
          </Checkbox>
        </Spacer>
      }
    />
  );
};

export default PatentFamiliesTable;
