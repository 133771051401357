import { OsrFilterState } from 'business/resources/osr/services/types';
import Spacer from 'ui/spacer';
import V2Table from 'ui/v2/table/table';
import { useGetAbandonedTrademark } from 'business/resources/osr/services/trademarkOsr.service';
import useTableColumn from 'technical/table/useTableColumn';
import { TRADEMARK_ABANDONMENT_COLUMNS } from './trademarkOsr.constant';
import SearchBar from 'ui/v2/tableActions/search';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import Flex from 'ui/flex';
import { RangePicker } from 'ui/date/datePicker';
import { endOfDay, endOfYear, startOfYear } from 'date-fns';

interface IAbandonmentList {
  filters: OsrFilterState;
  onChangeRangeDate: (from: Date | null, to: Date | null) => void;
}

const AbandonmentList = (props: IAbandonmentList) => {
  // const { generateExcel } = useGenerateExcelFile('trademark', props.filters);

  const abandonedTrademarkColumns = useTableColumn(
    TRADEMARK_ABANDONMENT_COLUMNS,
  );
  const abandonedTrademark = useGetAbandonedTrademark(
    {
      limit: abandonedTrademarkColumns.paginationState.pageSize,
      offset:
        (abandonedTrademarkColumns.paginationState.current - 1) *
        abandonedTrademarkColumns.paginationState.pageSize,
      dueDate: props.filters.dueDate,
      companyRef: props.filters.companyRef,
      countryCode: props.filters.countryCode,
    },
    abandonedTrademarkColumns.orderByState,
  );

  return (
    <Spacer direction="vertical" size="small">
      <Flex alignItems="center" justify="space-between">
        <Flex column style={{ width: '33%' }}>
          <RangePicker
            allowClear
            inputReadOnly={true}
            defaultValue={[startOfYear(new Date()), endOfYear(new Date())]}
            onChange={(e) => {
              props.onChangeRangeDate(
                e && e[0] ? new Date(endOfDay(e[0])) : e?.[0] ?? null,
                e && e[1] ? new Date(endOfDay(e[1])) : e?.[1] ?? null,
              );
            }}
          />
        </Flex>
      </Flex>

      <V2Table
        columns={abandonedTrademarkColumns.tableColumns}
        dataSource={abandonedTrademark.data}
        onChange={abandonedTrademarkColumns.handlePagination}
        loading={abandonedTrademark.loading}
        pagination={{
          current: abandonedTrademarkColumns.paginationState.current,
          pageSize: abandonedTrademarkColumns.paginationState.pageSize,
          total: abandonedTrademark.total,
        }}
        actions={
          <Spacer align="center" size="small">
            <SearchBar
              value={abandonedTrademark.searchAbandoned}
              onDebouncedChange={(e) =>
                abandonedTrademark.setSearchAbandoned(e)
              }
            />
            {/* <Button
              className="searchButton"
              type="primary"
              onClick={() => generateExcel('AFFIDAVIT')}
            >
              Exporter les Affidavits
            </Button> */}
            <EditColumnsButton
              initialColumns={TRADEMARK_ABANDONMENT_COLUMNS}
              visibleColumns={abandonedTrademarkColumns.visibleColumns}
              onChange={abandonedTrademarkColumns.toggleColumnVisibility}
            />
          </Spacer>
        }
      />
    </Spacer>
  );
};

export default AbandonmentList;
