import { DesignMap } from 'business/resources/design/services/types';
import { DomainMap } from 'business/resources/domain/types';
import { PatentMap } from 'business/resources/patent/types';
import { TrademarkMap } from 'business/resources/trademark/services/trademark.types';
import { GeneratedTableProps } from 'technical/hooks/useQueryForTable';
import { FilterField, FilterFieldProps } from 'ui/filters';

export const tabsFamily = [
  'patent',
  'trademark',
  'design',
  'domain',
  'all',
] as const;

export const resourceTypes = [
  'patent',
  'trademark',
  'design',
  'domain',
] as const;

export type TabsFamily = (typeof tabsFamily)[number];

export type ResourceType = (typeof resourceTypes)[number];

export const isTabsFamily = (filter: string): filter is TabsFamily => {
  return !!tabsFamily.find((family) => family === filter);
};

export const isResourceType = (resource: string): resource is ResourceType => {
  return !!resourceTypes.find((resourceType) => resourceType === resource);
};

const fields = [
  'id',
  'title',
  'countryCode',
  'status',
  'depositDate',
  'clientRef',
  'clientRef2',
  'holder',
  'effectiveHolder',
  'clientOfficer',
  'bdlOfficer',
  'inventors',
  'issueDate',
  'protectionCode',
  'publicationDate',
  'reservationDate',
  'expirationDate',
  'extension',
  'registrationDate',
  'firstRegistrationDate',
  'lastRegistrationDate',
  'nextRegistrationDate',
  'ownershipType',
  'depositPublicationIssueNumber',
  'classes',
  'showMaskedEvents',
  'dueDate',
  'type',
  'completionDate',
  'adjournedPublication',
] as const;

export type Fields = (typeof fields)[number];

export const isFamilyField = (familyField: string): familyField is Fields => {
  return !!fields.find((field) => field === familyField);
};

export type AdvancedFilterValues = 'family' | 'search' | 'filtersOpen' | 'page';

export type FilterFieldOptions<T extends FilterField<any>> = Omit<
  Parameters<T['component']>[0],
  keyof FilterFieldProps
>;
type Option = { code: string; label: string };

export interface FiltersData {
  countryCode: Option[];
  statuses: Option[];
  protectionCode: Option[];
  extensions: Option[];
  eventTypes: Option[];
}

export type ResourceData<Resource> = {
  data: Resource[];
  tableProps: GeneratedTableProps<Resource>;
  error?: Error;
};

export type ResourceMap = DomainMap | TrademarkMap | DesignMap | PatentMap;

export enum StatusFilter {
  Effective = 'effective',
  NotEffective = 'notEffective',
  Deposited = 'deposited',
  NotDeposited = 'notDeposited',
  Issued = 'issued',
  NotIssued = 'notIssued',
}

export type Resource = {
  id: number;
  alissiaId?: number | null;
  bdlRef?: string | null;
  clientRef?: string | null;
  holder?: string | null;
  depositNumber?: string | null | boolean;
  depositDate?: Date;
  priorities?:
    | null
    | {
        priorityNumber: string;
        priorityDate?: null | Date;
      }[];
  country?: null | {
    labelEn: string;
    labelFr: string;
  };
  bdlOfficer?: null | {
    email?: string | null;
  };
  bdlSecretary?: null | {
    email?: string | null;
  };
  title?: string | null;
};
