import { Tooltip } from 'antd';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import { getCountriesKey } from 'technical/countriesTranslation/constants';
import { formatDate } from 'technical/date';
import sanitizeStringList from 'technical/format';

const patentColumns = (t: TFunction) => ({
  patentFamilies: [
    {
      title: t('table.common.column.holder'),
      dataIndex: 'holder',
      key: 'holder',
      sorter: true,
    },
    {
      title: t('table.common.column.title', { context: 'patent' }),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      render: (_: any, record: any) => (
        <Link
          data-test-id="titleRedirect"
          className="titleRedirect"
          to={`/patent/family/${record.id}`}
        >
          {record.title}
        </Link>
      ),
    },
    {
      title: t('table.common.column.countries'),
      sorter: true,
      dataIndex: 'countriesFr',
      key: 'countriesFr',
      render: (_: any, data: any) => {
        return (
          <Tooltip title={data[getCountriesKey()]}>
            {data[getCountriesKey()]}
          </Tooltip>
        );
      },
    },
    {
      title: t('table.common.column.firstDepositNumber'),
      dataIndex: 'firstDepositNumber',
      key: 'firstDepositNumber',
      sorter: true,
    },
    {
      title: t('table.common.column.firstDepositDate'),
      dataIndex: 'firstDepositDate',
      key: 'firstDepositDate',
      render: (_: any, data: any) => {
        if (data.firstDepositDate) {
          return formatDate(new Date(data.firstDepositDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.clientRef'),
      dataIndex: 'clientRef',
      key: 'clientRef',
      sorter: true,
    },
    {
      title: t('table.common.column.bdlRef'),
      dataIndex: 'bdlRef',
      key: 'bdlRef',
      sorter: true,
    },
    {
      title: t('table.common.column.clientOfficer'),
      dataIndex: 'clientOfficer',
      key: 'clientOfficer',
      sorter: true,
    },
    {
      title: t('table.common.column.bdlOfficerName'),
      dataIndex: 'bdlOfficerName',
      key: 'bdlOfficerName',
      sorter: true,
    },
    {
      title: t('table.common.column.inventors'),
      dataIndex: 'inventors',
      key: 'inventors',
      render: (_: any, data: any) => sanitizeStringList(data?.inventors),
      sorter: true,
    },
  ],
});

export default patentColumns;
