import { Button, Checkbox, Tabs } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { columns as trademarkColumns } from './columns.constant';
import useTableColumn from 'technical/table/useTableColumn';
import {
  useDownloadTrademarkFamilies,
  useGetTrademarkFamilies,
} from 'business/resources/trademark/services/trademarkFamilies.service';
import { useState } from 'react';
import Table from 'ui/v2/table/table';
import Spacer from 'ui/spacer';
import TagResults from 'ui/tag/tagResults';
import SearchBar from 'ui/v2/tableActions/search';
import Routes from 'business/router/routes';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import MosaicList from './mosaicList';

const TrademarkFamiliesTable = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [isEffective, setIsEffective] = useState(true);
  const columns = trademarkColumns.trademarkFamilies(t);
  const trademaekFamiliesColumns = useTableColumn(columns);

  const { download, loading: loadingExport } = useDownloadTrademarkFamilies(
    columns,
    trademaekFamiliesColumns.visibleColumns,
    {
      isEffective,
      search,
    },
  );

  const { trademarkWithImages, loading, total } = useGetTrademarkFamilies({
    isEffective: isEffective,
    search: search,
    limit: trademaekFamiliesColumns.paginationState.pageSize,
    offset:
      (trademaekFamiliesColumns.paginationState.current - 1) *
      trademaekFamiliesColumns.paginationState.pageSize,
    orderBy: trademaekFamiliesColumns.orderByState,
  });
  return (
    <Tabs
      defaultActiveKey="1"
      items={[
        {
          key: '1',
          label: 'Tableau',
          children: (
            <Table
              columns={trademaekFamiliesColumns.tableColumns}
              dataTestId="designFamiliesTable"
              dataSource={trademarkWithImages ?? []}
              onChange={trademaekFamiliesColumns.handlePagination}
              loading={loading}
              pagination={{
                current: trademaekFamiliesColumns.paginationState.current,
                pageSize: trademaekFamiliesColumns.paginationState.pageSize,
                total,
              }}
              actions={
                <Spacer align="center" size="small">
                  <TagResults total={total} dataTestId="trademarkTotal" />
                  <SearchBar
                    value={search}
                    onDebouncedChange={(e) => setSearch(e)}
                  />
                  <Link
                    to={{
                      pathname: Routes.Search,
                      search: `?family=trademark&filtersOpen=true`,
                    }}
                  >
                    <Button
                      data-test-id="filterButton"
                      className="searchButton"
                      type="primary"
                    >
                      {t('table.common.advancedSearch')}
                    </Button>
                  </Link>
                  <Button
                    loading={loadingExport}
                    data-test-id="exportButton"
                    type="primary"
                    onClick={() => download()}
                  >
                    {t('table.common.exportButton')}
                  </Button>
                  <EditColumnsButton
                    initialColumns={columns}
                    visibleColumns={trademaekFamiliesColumns.visibleColumns}
                    onChange={trademaekFamiliesColumns.toggleColumnVisibility}
                  />
                  <Checkbox
                    checked={isEffective}
                    onChange={(e) => setIsEffective(e.target.checked)}
                  >
                    {t('table.common.effective')}
                  </Checkbox>
                </Spacer>
              }
            />
          ),
        },
        {
          key: '2',
          label: 'Mosaique',
          children: (
            <MosaicList<any>
              actions={
                <>
                  <TagResults total={total} dataTestId="trademarkTotal" />
                  <SearchBar
                    value={search}
                    onDebouncedChange={(e) => setSearch(e)}
                  />

                  <Link
                    to={{
                      pathname: Routes.Search,
                      search: `?family=trademark&filtersOpen=true`,
                    }}
                  >
                    <Button
                      data-test-id="filterButton"
                      className="searchButton"
                      type="primary"
                    >
                      {t('table.common.advancedSearch')}
                    </Button>
                  </Link>
                  <Button
                    loading={loadingExport}
                    data-test-id="exportButton"
                    type="primary"
                    onClick={() => download()}
                  >
                    {t('table.common.exportButton')}
                  </Button>
                  <Checkbox
                    checked={isEffective}
                    onChange={(e) => setIsEffective(e.target.checked)}
                  >
                    {t('table.common.effective')}
                  </Checkbox>
                </>
              }
              data={trademarkWithImages}
              config={{
                pagination: {
                  current: trademaekFamiliesColumns.paginationState.current,
                  pageSize: trademaekFamiliesColumns.paginationState.pageSize,
                  total,
                  onChange: (current, pageSize) =>
                    trademaekFamiliesColumns.handlePagination(
                      {
                        current: current,
                        pageSize: pageSize,
                      },
                      {},
                      {},
                      {
                        currentDataSource: [],
                        action: 'paginate',
                      },
                    ),
                },
                loading: loading,
              }}
            />
          ),
        },
      ]}
    ></Tabs>

    // <Table
    //   id="trademarkFamiliesTable"
    //   {...props}
    //   family="trademark"
    //   showEffectiveCheckbox
    //   defaultColumns={defaultColumns}
    //   header={
    //     props.displayHeader ? (
    //       <h1 className="headerTitle">{t('pages.header.trademarkFamilies')}</h1>
    //     ) : null
    //   }
    // />
  );
};

export default TrademarkFamiliesTable;
