import { DomainPageProps } from 'business/resources/domain/services/useGetDomain';
import { useTranslation } from 'react-i18next';
import Description from 'ui/v2/description/description';
import ErrorOverlay from 'ui/errorOverlay';
import HeaderTitles from 'ui/headerTitles';
import Spacer from 'ui/spacer';
import EventTable from 'business/resources/shared/components/event/eventTable';
import getDomainDetails, {
  Domain,
} from 'business/resources/domain/components/panels/domain';
import { useGetDomainEvents } from 'business/resources/domain/services/useGetDomainEvents';
import DnsData from 'business/resources/domain/components/dnsData';
import Card from 'ui/card';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import { useGetDomainInvoicesById } from 'business/resources/domain/services/useGetDomainInvoicesById';
import { isBdlAdmin } from 'business/admin/services/permission';
import DateRangeTableFilter from 'business/common/components/dateRangeTableFilter';
import DomainInvoiceTable from 'business/invoice/components/domainInvoiceTable';
import Flex from 'ui/flex';
import { getInvoiceTabLabel } from 'ui/tabs/helper';
import { useIsUserAdminOfCompanyQuery } from 'generated/graphql';
import ResourceLinks from 'business/common/components/resourceLinks';
import { Tabs, TabsProps } from 'antd';
import Routes from 'business/router/routes';
import Timeline from 'ui/timeline';
import ResourceComment from 'business/resources/shared/components/comments/resourceCommentList';

const DomainPage: React.VFC<DomainPageProps> = ({
  id,
  domain,
  domainDataState,
  isGandiDomain,
  timeline,
}) => {
  const { t } = useTranslation();
  const { data: events, tableProps: eventTableProps } = useGetDomainEvents(id);
  const { user } = useLoggedInAppContext();
  const { data: isUserAdminQuery } = useIsUserAdminOfCompanyQuery({
    variables: {
      companyId: domain?.company?.id ?? -1,
      userId: user.id,
    },
    skip: !domain?.company?.id,
  });

  const isUserAdmin = !!isUserAdminQuery?.userGroup.length;

  const {
    domainInvoices: { data, tableProps, filters },
  } = useGetDomainInvoicesById(id);

  const tabs: TabsProps['items'] = [
    {
      key: 'description',
      label: t('common.informations'),
      children: (
        <Description<Domain>
          details={getDomainDetails(t, isGandiDomain, domain)}
          data={domain}
        />
      ),
    },

    {
      key: 'events',
      label: t('table.title.event'),
      children: <EventTable dataSource={events} {...eventTableProps} />,
    },
    {
      key: 'comments',
      label: t('notes.title'),
      children: <ResourceComment id={id} resourceType="domain" />,
    },
  ];

  const showInvoices = (isBdlAdmin(user) || isUserAdmin) && !!data.length;
  return (
    <Spacer direction="vertical" size="middle">
      <Spacer direction="vertical" size="small">
        <HeaderTitles
          title={domain?.title?.toUpperCase() ?? ''}
          subtitle=""
          dataTestId="domainPage"
          family={{
            id: domain?.familyId,
            route: Routes.DomainFamily,
          }}
        />
        <ResourceLinks resource={domain} />
        <Timeline events={timeline} />
      </Spacer>
      <div className="detailResourceContainer">
        <ErrorOverlay {...domainDataState}>
          <Card>
            <Tabs defaultActiveKey="informations" items={tabs} type="card" />
          </Card>
        </ErrorOverlay>
      </div>
      <DnsData showPrivateDns={isGandiDomain} domainId={id} />
      {showInvoices && (
        <Flex column>
          <h3 className="tableTitle">
            {getInvoiceTabLabel(t, 'domain')({ tableProps })}
          </h3>
          <DomainInvoiceTable
            canSendBackInvoiceByMail={isBdlAdmin(user)}
            dataSource={data}
            {...tableProps}
            customAction={
              <DateRangeTableFilter
                {...filters}
                fromDateFilterProps={{ style: { marginLeft: 'auto' } }}
              />
            }
          />
        </Flex>
      )}
    </Spacer>
  );
};

export default DomainPage;
