import Table from 'ui/v2/table/table';
import TrademarkAffidavitUpload from './trademarkAffidavitUpload';

import Button from 'ui/button';
import Flex from 'ui/flex';
import {
  useDownloadAfu,
  useGetTrademarkAfu,
} from 'business/resources/trademark/services/trademarkAfu.service';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { columns } from './columns.constant';

interface ITrademarkAffidavit {
  trademarkId: number;
}

const TrademarkAffidavit = (props: ITrademarkAffidavit) => {
  const { t } = useTranslation();
  const { trademarkAfu, loading, refetch } = useGetTrademarkAfu(
    props.trademarkId,
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const { openAfu, downloadAfu, downloadSelectedRows } = useDownloadAfu();

  const onSelectChange = (_newSelectedRowKeys: React.Key[], rows: any[]) => {
    setSelectedRowKeys(rows);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys.map((s) => s.bucketPathId),
    onChange: onSelectChange,
  };

  const dowloadAll = () => {
    downloadSelectedRows(
      selectedRowKeys.map((s) => ({
        bucketPathId: s.bucketPathId,
        fileName: s.title,
      })),
    );
  };

  return (
    <>
      <Flex justify="flex-end">
        <TrademarkAffidavitUpload
          trademarkId={props.trademarkId}
          onUpload={refetch}
        />
      </Flex>
      <Button
        type="primary"
        onClick={dowloadAll}
        disabled={!(selectedRowKeys.length > 0)}
        loading={loading}
      >
        {t('common.download')}
      </Button>
      <Table
        loading={loading}
        dataSource={trademarkAfu}
        columns={columns.affidavits({
          onOpen: openAfu,
          onDownload: downloadAfu,
        })}
        rowKey={'bucketPathId'}
        onChange={() => {}}
        pagination={{}}
        rowSelection={rowSelection}
      />
    </>
  );
};

export default TrademarkAffidavit;
