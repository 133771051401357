import { EditOutlined } from '@ant-design/icons';
import { Checkbox, Popover } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';

interface IEditColumnsButton {
  initialColumns: ColumnsType<any>;
  visibleColumns: string[];
  onChange: (e: string) => void;
}

const EditColumnsButton = (props: IEditColumnsButton) => {
  const { t } = useTranslation();
  const columnEditor = useMemo(
    () => (
      <div>
        {props.initialColumns.map((col) => (
          <div key={col.key}>
            <Checkbox
              data-test-id={`columnsVisibilityButton-${col.key}`}
              checked={props.visibleColumns.includes(col.key as string)}
              onChange={() => props.onChange(col.key as string)}
            >
              {col.title}
            </Checkbox>
          </div>
        ))}
      </div>
    ),
    [props.visibleColumns, props.initialColumns],
  );
  return (
    <Popover content={columnEditor} trigger="click" placement="bottom">
      <Button
        icon={<EditOutlined />}
        type="primary"
        data-test-id="columnsVisibilityButton"
      >
        {t('table.common.editColumns')}
      </Button>
    </Popover>
  );
};

export default EditColumnsButton;
