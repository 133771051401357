import {
  CloseCircleOutlined,
  CloudUploadOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import { Form, Modal } from 'antd';
import { UploadFile } from 'antd/lib/upload';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Button from 'ui/button';
import Spacer from 'ui/spacer';
import { UploadChangeParam } from 'antd/es/upload';
import { RcFile } from 'technical/fileManagement/types';
import Dragger from 'antd/es/upload/Dragger';
import { TRADEMARK_AFU_FILE_TYPE } from 'business/resources/trademark/services/constant';
import { useUploadTrademarkAfu } from 'business/resources/trademark/services/trademarkAfu.service';

const TrademarkAffidavitUpload = (props: {
  trademarkId: number;
  onUpload: () => void;
  isBdlUser: boolean;
}) => {
  const { t } = useTranslation();

  const [form] = Form.useForm<{ file: UploadFile[] }>();
  const [uploadModal, setUploadModal] = useState(false);

  const { uploadAfu } = useUploadTrademarkAfu();
  const resetForm = () => {
    form.resetFields();
    setUploadModal(false);
  };
  const uploadFile = () => {
    form.validateFields().then((datas) => {
      setUploadModal(!uploadModal);
      uploadAfu({
        ...datas,
        trademarkId: props.trademarkId,
        file: datas.file,
      }).then(() => props.onUpload());
    });
  };
  return (
    <div>
      {!props.isBdlUser ? (
        <Button
          type="link"
          icon={<PlusCircleOutlined />}
          onClick={() => setUploadModal(!uploadModal)}
        >
          {t('pages.trademark.affidavitUpload')}
        </Button>
      ) : null}
      <Modal
        title="Ajouter une preuve d'usage"
        open={uploadModal}
        closeIcon={<CloseCircleOutlined />}
        onCancel={() => setUploadModal(!uploadModal)}
        footer={[
          <Button key="cancel" onClick={resetForm}>
            {t('common.cancel')}
          </Button>,
          <Button key="submit" type="primary" onClick={uploadFile}>
            {t('common.confirm')}
          </Button>,
        ]}
      >
        <Form layout="vertical" form={form}>
          <Spacer size="small" direction="vertical">
            <Form.Item
              name="file"
              required
              getValueFromEvent={(e: UploadChangeParam<RcFile>) => e.fileList}
            >
              <Dragger
                accept={TRADEMARK_AFU_FILE_TYPE.join(', ')}
                multiple
                maxCount={5}
                beforeUpload={() => false /** prevent automatic upload */}
              >
                <Spacer direction="vertical" size="small">
                  <span> {t('pages.trademark.affidavitUpload')}</span>
                  <p className="ant-upload-drag-icon">
                    <CloudUploadOutlined />
                  </p>
                </Spacer>
              </Dragger>
            </Form.Item>
          </Spacer>
        </Form>
      </Modal>
    </div>
  );
};

export default TrademarkAffidavitUpload;
