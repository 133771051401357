import { MIME_TYPES_BY_FILE_FORMAT } from 'technical/fileManagement/constants';

export const TRADEMARK_AFU_FILE_TYPE = [
  MIME_TYPES_BY_FILE_FORMAT.zip,
  MIME_TYPES_BY_FILE_FORMAT.xlsx,
  MIME_TYPES_BY_FILE_FORMAT.pdf,
  MIME_TYPES_BY_FILE_FORMAT.jpeg,
  MIME_TYPES_BY_FILE_FORMAT.doc,
  MIME_TYPES_BY_FILE_FORMAT.docx,
];
