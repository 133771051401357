import { Link } from 'react-router-dom';
import { AllResults } from 'business/advancedSearch/services/types';
import { ColumnGenerationData } from 'ui/table/types';
import { TFunction } from 'i18next';
import { FAMILIES_TABLE_IMAGE_STYLE } from 'ui/imageDisplay/constants';

import {
  generateFamilyPathByAssetType,
  generatePathByAssetType,
} from 'business/common/services';
import { ResourceType, isResourceType } from 'business/common/services/types';
import { TableOutlined } from '@ant-design/icons';
import { getCountryColumn } from 'technical/countriesTranslation/constants';
import Loader from 'ui/loader';
import { Image } from 'antd';

const getAllResultsTableColumns = (
  t: TFunction,
): ColumnGenerationData<AllResults> => [
  {
    key: 'asset',
    render: (assetType: string) => {
      return t('table.common.column.asset', { context: assetType });
    },
  },
  {
    key: 'holder',
  },
  {
    key: 'title',
    render: (_, record: AllResults) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={generatePathByAssetType(record.asset as ResourceType, record.id)}
      >
        {record.title}
      </Link>
    ),
  },
  {
    key: 'id',
    translationKey: 'image',
    sorter: false,
    width: 200,
    render: (_: any, data: any) => {
      if (data.image === 'loading') {
        return <Loader />;
      }
      if (data.image) {
        return (
          <Image
            src={data.image}
            style={{ ...FAMILIES_TABLE_IMAGE_STYLE, objectFit: 'contain' }}
          />
        );
      }
    },
  },

  {
    ...getCountryColumn(),
  },
  {
    key: 'depositNumber',
  },
  {
    key: 'depositDate',
  },
  {
    key: 'clientRef',
  },
  {
    key: 'bdlRef',
  },
  {
    key: 'misRef',
    checked: false,
  },
  {
    key: 'bdlOfficer',
    checked: false,
  },
  {
    key: 'clientOfficer',
    checked: false,
  },
  {
    key: 'status',
  },
  {
    key: 'familyId',
    translationKey: 'family',
    render: (_, { asset, familyId }: AllResults) =>
      asset &&
      isResourceType(asset) && (
        <Link to={generateFamilyPathByAssetType(asset, familyId)}>
          <TableOutlined />
        </Link>
      ),
  },
];

export default getAllResultsTableColumns;
