import { Button, Popover } from 'antd';

interface IClassesPopover {
  classes: {
    id?: string;
    classCode?: number;
    classDetail?: string;
  }[];
}

const ClassesPopover = (props: IClassesPopover) => {
  const sortedClasses = [...props.classes].sort(
    (a, b) => (a.classCode || 0) - (b.classCode || 0),
  );

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
      {sortedClasses.map((item, index) => (
        <Popover
          key={item.id || item.classCode}
          content={item.classDetail || 'Aucun détail disponible'}
          title={`Code: ${item.classCode || 'N/A'}`}
          trigger="click"
        >
          <Button type="text" style={{ padding: 0, margin: 0 }}>
            {item.classCode ?? 'N/A'}
            {index < sortedClasses.length - 1 ? ' -' : ''}
          </Button>
        </Popover>
      ))}
    </div>
  );
};

export default ClassesPopover;
