import { generatePathByAssetType } from 'business/common/services';
import { getCountryColumn } from 'technical/countriesTranslation/constants';
import { DesignEvent } from 'business/resources/events/services/types';
import { Link } from 'react-router-dom';
import { ColumnGenerationData } from 'ui/table/types';

const getDesignEventColumns = (): ColumnGenerationData<DesignEvent> => [
  {
    key: 'type',
    alwaysVisible: true,
    translationKey: 'eventType',
    ellipsis: true,
  },
  { key: 'holder', checked: false, ellipsis: true },
  { key: 'effectiveHolder', checked: false, ellipsis: true },
  {
    key: 'title',
    alwaysVisible: true,
    translationKey: 'designName',
    render: (_, { assetId, title }: DesignEvent) => (
      <Link
        data-test-id="titleRedirect"
        className="titleRedirect"
        to={generatePathByAssetType('design', assetId)}
      >
        {title}
      </Link>
    ),
  },

  { ...getCountryColumn(), alwaysVisible: true },
  { key: 'dueDate', alwaysVisible: true, ellipsis: true },
  { key: 'depositNumber', checked: false, ellipsis: true },
  { key: 'depositDate', checked: false, ellipsis: true },
  { key: 'bdlRef', checked: false, ellipsis: true },
  { key: 'clientRef', checked: false, ellipsis: true },
  { key: 'clientRef2', checked: false, ellipsis: true },
  { key: 'bdlOfficer', ellipsis: true },
  { key: 'clientOfficer', ellipsis: true },
  { key: 'adjournedPublication', ellipsis: true },
];

export default getDesignEventColumns;
