import { Input, Select, Tabs } from 'antd';
import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';
import { useOsrFilters } from 'business/resources/osr/services/useOsrFilters';
import { useLoggedInAppContext } from 'business/AppBootstrapper';
import NotFoundPage from 'ui/notFoundPage';
import { useGetCountriesListQuery } from 'generated/graphql';
import TrademarkAcumass from 'business/resources/osr/components/trademark/trademarkAcumass';

import AffidavitList from 'business/resources/osr/components/trademark/affidavitList';
import TrademarkAnomaly from 'business/resources/osr/components/trademark/trademarkAnomaly';
import RenewalList from 'business/resources/osr/components/trademark/renewalList';
import AbandonmentList from 'business/resources/osr/components/trademark/abandonmentList';

const OsrTrademarkPage = () => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();
  const { filters, actions } = useOsrFilters();

  const { data } = useGetCountriesListQuery();

  const countries = data?.country ?? [];

  if (!user.osrAccess) {
    return <NotFoundPage />;
  }

  const filterOption = (
    input: string,
    option?: { label: string; value: string },
  ) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase());

  return (
    <ConnectedPage translationKey="dashboard">
      <Spacer direction="vertical">
        <h1 className="headerTitle">
          {t('pages.osr.title', { context: 'trademark' })}
        </h1>
        <Spacer direction="vertical" size="small">
          <Spacer direction="vertical" size="small">
            <div style={{ width: '33%' }}>
              <Input
                defaultValue={filters.companyRef}
                placeholder={t('pages.osr.filters.clientRef')}
                onChange={(e) => actions.onChangeCompanyRef(e.target.value)}
              />
            </div>
            <Select
              showSearch
              mode="multiple"
              style={{ width: '33%' }}
              placeholder="Filtrer par pays"
              optionFilterProp="children"
              onChange={(value) => actions.onChangeCountries(value)}
              filterOption={filterOption}
              options={countries.map((c) => {
                return { label: c.labelFr, value: c.code };
              })}
            />
          </Spacer>

          <div>
            <Tabs
              size="large"
              type="card"
              items={[
                // Renouvellements
                {
                  key: 'renewal',
                  label: t('pages.osr.title', { context: 'tabRenew' }),
                  children: (
                    <RenewalList
                      filters={filters}
                      actions={{
                        onChangeOffice: actions.onChangeOffice,
                        onChangeRangeDate: actions.onChangeRangeDate,
                      }}
                    />
                  ),
                },
                {
                  key: 'abandoned',
                  label: 'Liste des abandons',
                  children: (
                    <AbandonmentList
                      filters={filters}
                      onChangeRangeDate={actions.onChangeRangeDate}
                    />
                  ),
                },
                // Affidavits
                {
                  key: 'affidavit',
                  label: `Liste des affidavits`,
                  children: (
                    <AffidavitList
                      onChangeRangeDate={actions.onChangeRangeDate}
                      filters={filters}
                    />
                  ),
                },

                // ACUMASS

                {
                  key: 'Acumass',
                  label: 'Mise à jour Acumass',
                  children: <TrademarkAcumass filters={filters} />,
                },

                // ANOMALIES
                {
                  key: 'TrademarkAnomaly',
                  label: t('pages.osr.title', { context: 'tabAnomaly' }),
                  children: <TrademarkAnomaly filters={filters} />,
                },
              ]}
            />
          </div>
        </Spacer>
      </Spacer>
    </ConnectedPage>
  );
};

export default OsrTrademarkPage;
