import { Image, List, TablePaginationConfig } from 'antd';
import Spacer from 'ui/spacer';
import MosaicCard from 'ui/mosaic/mosaicCard';
import { TrademarkFamilies } from 'business/resources/trademark/services/trademark.types';
import Flex from 'ui/flex';
import { format, parseISO } from 'date-fns';
import CountryList from 'ui/data/textWithEllipsis';
import DateNumber from 'ui/data/dateNumber';
import { useTranslation } from 'react-i18next';
import { generatePath, useHistory } from 'react-router';
import Routes from 'business/router/routes';
import Loader from 'ui/loader';
import { FAMILIES_TABLE_IMAGE_STYLE } from 'ui/imageDisplay/constants';
import { overrideNoResultStyle } from './constant';

type FamilyResource = TrademarkFamilies;
interface IMosaicList<T> {
  data: T[];
  actions: React.ReactNode;
  config: {
    loading: boolean;
    pagination?: TablePaginationConfig;
  };
}

const MosaicList = <T extends FamilyResource>({
  actions,
  data,
  config,
}: IMosaicList<T>) => {
  const { t } = useTranslation();
  const history = useHistory();
  return (
    <Spacer direction="vertical" size="small">
      <Spacer direction="horizontal" align="center" size="small">
        {actions}
      </Spacer>
      <List
        loading={config.loading}
        pagination={{
          pageSize: config.pagination?.pageSize ?? 30,
          current: config.pagination?.current ?? 1,
          total: config.pagination?.total ?? 0,
          onChange: (current, pageSize) => {
            config.pagination?.onChange?.(current, pageSize);
          },
        }}
        grid={{
          gutter: 16,
          xs: 1,
          sm: 1,
          md: 2,
          lg: 3,
          xl: 3,
          xxl: 4,
        }}
        dataSource={data}
        renderItem={(item) => (
          <MosaicCard
            onClick={() =>
              history.push({
                pathname: generatePath(Routes.TrademarkFamily, {
                  id: item.id?.toString() ?? '',
                }),
              })
            }
            header={
              item.image === 'loading' ? (
                <Loader />
              ) : !item.image ? (
                <div style={overrideNoResultStyle}>
                  <span>{item.title}</span>
                </div>
              ) : (
                <Image
                  src={item.image ?? ''}
                  style={{ ...FAMILIES_TABLE_IMAGE_STYLE }}
                />
              )
            }
          >
            <Flex column>
              <Spacer direction="horizontal" size="xxsmall">
                <span style={{ fontWeight: 'bold' }}>
                  {t('table.common.column.title', { context: 'design' })}:{' '}
                </span>
                <span>{item.title}</span>
              </Spacer>
              <Spacer direction="horizontal" size="xxsmall">
                <DateNumber
                  name={t('table.common.column.deposit')}
                  number={item.firstDepositNumber}
                  date={
                    item.firstDepositDate
                      ? format(parseISO(item.firstDepositDate), 'MMM dd, yyyy')
                      : null
                  }
                />
              </Spacer>
              <Spacer direction="horizontal" size="xxsmall">
                <CountryList
                  name={t('table.common.column.countries')}
                  text={item.countriesFr ?? ''}
                />
              </Spacer>
            </Flex>
          </MosaicCard>
        )}
      />
    </Spacer>
  );
};

export default MosaicList;
