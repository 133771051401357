import { Patent } from 'business/resources/patent/types';
import { Trademark } from 'business/resources/trademark/services/trademark.types';
import {
  pipe,
  removeCommas,
  removeSlashes,
  removeSpaces,
} from 'technical/format/string';
import { isDefined } from 'technical/validation';
import { PubAndDepDatesAndNums } from './types';

export const replaceLink = (link: string, publicationNumber: string) =>
  link.replace('$$$', removeSpaces(publicationNumber));

/**
 * Function to get the official link of an asset:
 * * check if the condition is met
 * * check if the value is defined
 * * format the value
 * * return the link with the formatted value
 * @param cond - condition to check before returning link
 * @param key - key of the asset to get the value to put in the link from
 * @param fns - functions to apply to the value before putting it in the link
 * @param getLink - link to replace or function to get the link to replace depending on the asset
 * @returns function to get the official link, returns null if the condition is not met or the value is not defined
 */
export const getOfficialLink =
  <T extends { [keys: string]: any }>(cond?: (asset: T) => boolean) =>
  (key: keyof T, ...fns: ((str: string, asset: T) => string)[]) =>
  (getLink: string | ((asset: T) => string | null)) =>
  (asset: T) => {
    const { [key]: value } = asset;
    if ((cond && !cond(asset)) || !isDefined(value)) {
      return null;
    }
    const link = typeof getLink === 'function' ? getLink(asset) : getLink;
    return link
      ? replaceLink(link, pipe(...fns)(value as string, asset))
      : null;
  };

// Publication date and deposit date helpers

export const isAlreadyPublished = <
  T extends Pick<
    PubAndDepDatesAndNums,
    'publicationDate' | 'publicationNumber'
  >,
>({
  publicationDate,
  publicationNumber,
}: T) =>
  isDefined(publicationDate) &&
  isDefined(publicationNumber) &&
  !!(new Date() > new Date(publicationDate));

export const isAlreadyPublishedAndDeposited = <
  T extends PubAndDepDatesAndNums,
>({
  depositDate,
  depositNumber,
  ...rest
}: T) =>
  isAlreadyPublished(rest) &&
  isDefined(depositDate) &&
  isDefined(depositNumber);

// Patent function helpers

export const formatUS = (depositNumber: string, patent: Patent) =>
  pipe(
    (d) => d.replace(/^US/, ''),
    removeSpaces,
    removeCommas,
    removeSlashes,
  )(depositNumber, patent);

// Trademark protection helpers

/**
 * Check if the protection is a protection exception
 * * if the routeCode is NAT (National) or PCT (International)
 */
export const isProtectionException = (protection: Trademark['protection']) =>
  protection && ['NAT', 'PCT'].includes(protection.routeCode);

/**
 * Check if the protection is a national protection
 * * if protectionCode is MQ (Marque) and routeCode is NAT (National)
 */
export const isProtectionNational = (protection: Trademark['protection']) =>
  protection?.protectionCode === 'MQ' && protection?.routeCode === 'NAT';

/**
 * Check if the protection is a Madrid Protocol
 * * if protectionCode is MQ (Marque) and routeCode is WO (International)
 */
export const isProtectionMadridProtocol = (
  protection: Trademark['protection'],
) => protection?.protectionCode === 'MQ' && protection?.routeCode === 'WO';
