import { Button, Checkbox } from 'antd';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  useDownloadDesignFamilies,
  useGetDesignFamilies,
} from 'business/resources/design/services/designFamilies.service';
import { useState } from 'react';
import designColumns from 'business/resources/design/components/constant';
import useTableColumn from 'technical/table/useTableColumn';
import Spacer from 'ui/spacer';
import TagResults from 'ui/tag/tagResults';
import SearchBar from 'ui/v2/tableActions/search';
import Routes from 'business/router/routes';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';
import Table from 'ui/v2/table/table';

const DesignFamiliesTable = () => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');
  const [isEffective, setIsEffective] = useState(true);

  const columns = designColumns(t).designFamilies;
  const designFamiliesColumns = useTableColumn(columns);

  const { download, loading: loadingExport } = useDownloadDesignFamilies(
    columns,
    designFamiliesColumns.visibleColumns,
    {
      isEffective,
      search,
    },
  );

  const { designWithImages, loading, total } = useGetDesignFamilies({
    isEffective: isEffective,
    search: search,
    limit: designFamiliesColumns.paginationState.pageSize,
    offset:
      (designFamiliesColumns.paginationState.current - 1) *
      designFamiliesColumns.paginationState.pageSize,
    orderBy: designFamiliesColumns.orderByState,
  });

  return (
    <Table
      columns={designFamiliesColumns.tableColumns}
      dataTestId="designFamiliesTable"
      dataSource={designWithImages ?? []}
      onChange={designFamiliesColumns.handlePagination}
      loading={loading}
      pagination={{
        current: designFamiliesColumns.paginationState.current,
        pageSize: designFamiliesColumns.paginationState.pageSize,
        total,
      }}
      actions={
        <Spacer align="center" size="small">
          <TagResults total={total} dataTestId="designTotal" />
          <SearchBar value={search} onDebouncedChange={(e) => setSearch(e)} />
          <Link
            to={{
              pathname: Routes.Search,
              search: `?family=design&filtersOpen=true`,
            }}
          >
            <Button
              data-test-id="filterButton"
              className="searchButton"
              type="primary"
            >
              {t('table.common.advancedSearch')}
            </Button>
          </Link>
          <Button
            loading={loadingExport}
            data-test-id="exportButton"
            type="primary"
            onClick={() => download()}
          >
            {t('table.common.exportButton')}
          </Button>
          <EditColumnsButton
            initialColumns={columns}
            visibleColumns={designFamiliesColumns.visibleColumns}
            onChange={designFamiliesColumns.toggleColumnVisibility}
          />
          <Checkbox
            checked={isEffective}
            onChange={(e) => setIsEffective(e.target.checked)}
          >
            {t('table.common.effective')}
          </Checkbox>
        </Spacer>
      }
    />
  );
};

export default DesignFamiliesTable;
