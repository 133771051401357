import React from 'react';
import { Input } from 'antd';
import classNames from 'classnames';
import styles from './index.module.scss';
import { useDebounce } from 'technical/hooks/useDebounce';

interface SearchBarProps {
  onDebouncedChange: (value: string) => void; // Appelé après le debounce
  debounceDelay?: number; // Temps de debounce en ms
  value?: string; // Valeur initiale
  placeholder?: string;
  allowClear?: boolean;
}

const SearchBar: React.VFC<SearchBarProps> = ({
  onDebouncedChange,
  debounceDelay = 300, // Par défaut à 300ms
  value = '',
  placeholder = 'Search...',
  allowClear = true,
  ...rest
}) => {
  const {
    debouncedValue,
    setValue,
    value: dValue,
  } = useDebounce({
    defaultValue: value,
    delay: debounceDelay,
  });

  React.useEffect(() => {
    onDebouncedChange(debouncedValue); // Utilise debouncedValue
  }, [debouncedValue]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
  };

  return (
    <Input
      className={classNames('searchBar', styles.searchBarStyle)}
      placeholder={placeholder}
      allowClear={allowClear}
      value={dValue}
      onChange={handleChange}
      {...rest}
    />
  );
};
export default SearchBar;
