import {
  useDownloadDesignByFamilyId,
  useGetDesignByFamilyId,
} from 'business/resources/design/services/designFamily.service';
import { useState } from 'react';
import designColumns from './constant';
import { useTranslation } from 'react-i18next';
import useTableColumn from 'technical/table/useTableColumn';
import Table from 'ui/v2/table/table';
import Spacer from 'ui/spacer';
import TagResults from 'ui/tag/tagResults';
import SearchBar from 'ui/v2/tableActions/search';
import Button from 'ui/button';
import EditColumnsButton from 'ui/v2/tableActions/editColumnsButton';

const DesignFamilyTable = (props: { familyId: string }) => {
  const { t } = useTranslation();
  const [search, setSearch] = useState('');

  const columns = designColumns(t).designFamily;
  const designFamilyColumns = useTableColumn(columns);

  const { data, loading, total } = useGetDesignByFamilyId({
    id: props.familyId,
    search,
    orderBy: designFamilyColumns.orderByState,
    limit: designFamilyColumns.paginationState.pageSize,
    offset:
      (designFamilyColumns.paginationState.current - 1) *
      designFamilyColumns.paginationState.pageSize,
  });

  const { download, loading: loadingExport } = useDownloadDesignByFamilyId(
    columns,
    designFamilyColumns.visibleColumns,
    {
      search,
      id: props.familyId,
    },
  );

  return (
    <Table
      columns={designFamilyColumns.tableColumns}
      dataTestId="designFamilyTable"
      dataSource={data ?? []}
      onChange={designFamilyColumns.handlePagination}
      loading={loading}
      pagination={{
        current: designFamilyColumns.paginationState.current,
        pageSize: designFamilyColumns.paginationState.pageSize,
        total,
      }}
      actions={
        <Spacer align="center" size="small">
          <TagResults total={total} dataTestId="designTotal" />
          <SearchBar value={search} onDebouncedChange={(e) => setSearch(e)} />
          <Button
            loading={loadingExport}
            data-test-id="exportButton"
            type="primary"
            onClick={() => download()}
          >
            {t('table.common.exportButton')}
          </Button>
          <EditColumnsButton
            initialColumns={columns}
            visibleColumns={designFamilyColumns.visibleColumns}
            onChange={designFamilyColumns.toggleColumnVisibility}
          />
        </Spacer>
      }
    />
  );
};

export default DesignFamilyTable;
