import React from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import TrademarkFamiliesTable from 'business/resources/trademark/components/trademarkFamiliesTable';
import { useTranslation } from 'react-i18next';

const TrademarkFamiliesPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ConnectedPage translationKey="trademarks">
      <h1 className="headerTitle">{t('pages.header.trademarkFamilies')}</h1>
      <TrademarkFamiliesTable data-test-id="trademarkFamiliesTable" />
    </ConnectedPage>
  );
};

export default TrademarkFamiliesPage;
