import ContactBdlByMailButton from 'business/common/components/contactBdlByMailButton';
import { ResourceType } from 'business/common/services/types';
import { TFunction } from 'i18next';
import { PanelOptions } from 'ui/v2/description/types';
import Flex from 'ui/flex';

export type Resource = {
  bdlOfficer?: null | {
    fullName?: null | string;
  };
  bdlSecretary?: null | {
    fullName?: null | string;
  };
};

export const bdlOfficerAndSecretaryColumns = (
  t: TFunction,
  resourceType: ResourceType,
  resource: any,
): PanelOptions<Resource> => ({
  key: 'bdlOfficer',
  label: ({ bdlSecretary }) =>
    bdlSecretary?.fullName
      ? t('table.common.column.headOfficerSecretary')
      : t('table.common.column.bdlOfficer'),
  render: ({ bdlOfficer, bdlSecretary }) => {
    return (
      <Flex alignItems="center">
        <span>
          {bdlOfficer?.fullName +
            (bdlSecretary ? ` | ${bdlSecretary?.fullName}` : '')}
        </span>
        <div style={{ marginLeft: '10px' }}>
          <ContactBdlByMailButton
            resource={resource}
            resourceType={resourceType}
          />
        </div>
      </Flex>
    );
  },
});
