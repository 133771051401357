import { TabsFamily } from 'business/common/services/types';
import { useEffect } from 'react';
import { removeUndefinedField } from 'technical/validation';
import {
  EventAdvancedFilters,
  EventResourcesData,
} from 'business/resources/events/services/types';
import { OrderBy } from 'technical/filters/parser/commonParser/orderByParser';
import { useGetAllEvents } from './useGetAllEvents';
import { useGetDesignEvents } from './useGetDesignEvent';
import { useGetDomainEvent } from './useGetDomainEvent';
import { useGetPatentEvent } from './useGetPatentEvent';
import { useGetTrademarkEvent } from './useGetTrademarkEvent';

/**
 * Fetch events of all types
 * @returns
 */
export const useFetchAllEvents = (
  eventAdvancedFilters: EventAdvancedFilters,
  setEventAdvancedFilters: (filters: EventAdvancedFilters) => void,
  onError: (err: Error) => void,
  search?: string,
): EventResourcesData => {
  const buildHookParams = <T>(family: TabsFamily) => ({
    filterValues: removeUndefinedField(
      eventAdvancedFilters[family]?.filters ?? {},
    ),
    overrideOrderBy: [
      eventAdvancedFilters[family]?.sort ?? {},
      (sort: OrderBy<T>) => {
        setEventAdvancedFilters({
          ...eventAdvancedFilters,
          [family]: {
            ...eventAdvancedFilters[family],
            sort,
          },
        });
      },
    ] as const,
    search,
  });

  const {
    data: patentData,
    tableProps: patentTableProps,
    error: patentError,
  } = useGetPatentEvent(buildHookParams('patent'));
  const {
    data: designData,
    tableProps: designTableProps,
    error: designError,
  } = useGetDesignEvents(buildHookParams('design'));
  const {
    data: trademarkData,
    tableProps: trademarkTableProps,
    error: trademarkError,
  } = useGetTrademarkEvent(buildHookParams('trademark'));
  const {
    data: domainData,
    tableProps: domainTableProps,
    error: domainError,
  } = useGetDomainEvent(buildHookParams('domain'));
  const {
    data: allEventsData,
    tableProps: allEventsTableProps,
    error: allEventsError,
  } = useGetAllEvents(buildHookParams('all'));

  useEffect(() => {
    const err =
      patentError ??
      designError ??
      trademarkError ??
      domainError ??
      allEventsError;
    if (err) {
      onError(err);
    }
  }, [patentError, designError, trademarkError, domainError]);

  return {
    allEvents: {
      data: allEventsData,
      tableProps: allEventsTableProps,
    },
    patentEvents: {
      data: patentData,
      tableProps: patentTableProps,
    },
    designEvents: {
      data: designData,
      tableProps: designTableProps,
    },
    trademarkEvents: {
      data: trademarkData,
      tableProps: trademarkTableProps,
    },
    domainEvents: {
      data: domainData,
      tableProps: domainTableProps,
    },
  };
};
