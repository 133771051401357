import Table from 'ui/v2/table/table';
import TrademarkAffidavitUpload from './trademarkAffidavitUpload';

import Button from 'ui/button';
import Flex from 'ui/flex';
import {
  useDeleteAfu,
  useDownloadAfu,
  useGetTrademarkAfu,
} from 'business/resources/trademark/services/trademarkAfu.service';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { columns } from './columns.constant';
import confirmationModal from 'ui/modalConfirm';
import { isBdlAdmin, isBdlUser } from 'business/admin/services/permission';
import { useLoggedInAppContext } from 'business/AppBootstrapper';

interface ITrademarkAffidavit {
  trademarkId: number;
}

const TrademarkAffidavit = (props: ITrademarkAffidavit) => {
  const { t } = useTranslation();
  const { user } = useLoggedInAppContext();
  const { trademarkAfu, loading, refetch } = useGetTrademarkAfu(
    props.trademarkId,
  );
  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>([]);
  const { openAfu, downloadAfu, downloadSelectedRows } = useDownloadAfu();
  const { deleteAfu } = useDeleteAfu();
  const isBdlAdminOrUser = isBdlAdmin(user) || isBdlUser(user);

  const dowloadAll = () => {
    downloadSelectedRows(
      selectedRowKeys.map((s) => ({
        bucketPathId: s.bucketPathId,
        fileName: s.title,
      })),
    );
  };

  const deleteTrademarkAfu = (id: string) => {
    confirmationModal(
      t,
      () =>
        deleteAfu(id).then(() => {
          refetch();
        }),
      'Confirmez-vous la suppression du document ?',
    );
  };

  return (
    <Table
      loading={loading}
      dataSource={trademarkAfu}
      columns={columns.affidavits({
        onOpen: openAfu,
        onDownload: downloadAfu,
        onDelete: deleteTrademarkAfu,
        isBdlUser: isBdlAdminOrUser,
      })}
      actions={
        <Flex justify="space-between" style={{ width: '100%' }}>
          <Button
            type="primary"
            onClick={dowloadAll}
            disabled={!(selectedRowKeys.length > 0)}
            loading={loading}
          >
            {t('common.download')}
          </Button>
          <Flex justify="flex-end">
            <TrademarkAffidavitUpload
              trademarkId={props.trademarkId}
              onUpload={refetch}
              isBdlUser={isBdlAdminOrUser}
            />
          </Flex>
        </Flex>
      }
      rowKey={'bucketPathId'}
      onChange={() => {}}
      pagination={{}}
      rowSelection={{
        selectedRowKeys: selectedRowKeys.map((s) => s.bucketPathId),
        onChange: (_newSelectedRowKeys: React.Key[], rows: any[]) => {
          setSelectedRowKeys(rows);
        },
      }}
    />
  );
};

export default TrademarkAffidavit;
