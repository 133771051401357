import { TabsFamily } from 'business/common/services/types';
import { useGetDomainFamily } from 'business/resources/domain/services/useGetDomainFamily';
import { useGetPatentFamily } from 'business/resources/patent/services';
import { useEffect } from 'react';
import { isDefined, removeUndefinedField } from 'technical/validation';
import {
  AdvancedFilters,
  ResourcesData,
} from 'business/advancedSearch/services/types';
import logger from 'technical/logger';
import errorReporting from 'technical/error-reporting';
import { notification } from 'antd';
import { useTranslation } from 'react-i18next';
import { StorageKey, storageKeys } from 'technical/storage/constants';
import { OrderBy } from 'technical/filters/parser/commonParser/orderByParser';
import {
  defaultTabOpened,
  resourceTabsOrder,
} from 'business/advancedSearch/components/constants';
import { useGetAllResults } from './useGetAllResults';

import { useGetTrademarkFamily } from 'business/resources/trademark/services/trademarkFamily.service';
import { useGetDesignFamily } from 'business/resources/design/services/designFamily.service';

/**
 * Fetch Trademark, Patent, Domain et Designs based on search input
 * @returns
 */
export const useFetchAllResources = (
  advancedFilters: AdvancedFilters,
  setAdvancedFilters: (filters: AdvancedFilters) => void,
  resetToPreviousValue: () => void,
  search?: string,
): { resourcesData: ResourcesData; firstNonEmptyFamily: TabsFamily } => {
  const { t } = useTranslation();
  const buildHookParams = <T>(family: TabsFamily, storageKey?: StorageKey) => ({
    filterValues: removeUndefinedField(advancedFilters[family]?.filters ?? {}),
    overrideOrderBy: [
      advancedFilters[family]?.sort ?? {},
      (sort: OrderBy<T>) => {
        setAdvancedFilters({
          ...advancedFilters,
          [family]: {
            ...advancedFilters[family],
            sort,
          },
        });
      },
    ] as const,
    storageKey,
    search,
  });

  const {
    data: patentData,
    tableProps: patentTableProps,
    error: patentError,
  } = useGetPatentFamily(buildHookParams('patent', storageKeys.patentSearch));
  const {
    data: trademarkData,
    tableProps: trademarkTableProps,
    error: trademarkError,
  } = useGetTrademarkFamily(
    buildHookParams('trademark', storageKeys.trademarkSearch),
  );
  const {
    data: designData,
    tableProps: designTableProps,
    imageList,
    error: designError,
  } = useGetDesignFamily(buildHookParams('design', storageKeys.designSearch));
  const {
    data: domainData,
    tableProps: domainTableProps,
    error: domainError,
  } = useGetDomainFamily(buildHookParams('domain', storageKeys.domainSearch));

  const {
    data: allResults,
    tableProps: allResultsTableProps,
    error: allResultsError,
  } = useGetAllResults(buildHookParams('all'));

  useEffect(() => {
    const err =
      patentError ??
      designError ??
      trademarkError ??
      domainError ??
      allResultsError;
    if (err) {
      logger.error(err);
      errorReporting.error(err);
      resetToPreviousValue();
      notification.error({
        message: t('errors.filterError'),
      });
    }
  }, [patentError, designError, trademarkError, domainError]);

  const resourcesData = {
    patent: {
      data: patentData,
      tableProps: patentTableProps,
    },
    trademark: {
      data: trademarkData,
      tableProps: trademarkTableProps,
    },
    domain: {
      data: domainData,
      tableProps: domainTableProps,
    },
    design: {
      data: designData
        .map((d) => {
          const image = imageList.filter((i) => i.familyId === d.familyId);
          return {
            ...d,
            image: image[0]?.image,
          };
        })
        .filter(isDefined),
      tableProps: designTableProps,
    },
    all: {
      data: allResults,
      tableProps: allResultsTableProps,
    },
  };

  const firstNonEmptyFamilyIndex = resourceTabsOrder.findIndex(
    (f) => resourcesData[f].data.length,
  );

  const firstNonEmptyFamily =
    firstNonEmptyFamilyIndex === -1
      ? defaultTabOpened
      : resourceTabsOrder[firstNonEmptyFamilyIndex];

  return {
    resourcesData,
    firstNonEmptyFamily,
  };
};
