import { useGetTrademarkImagesByFamilyIdsQuery } from 'generated/graphql';
import { isDefined } from 'technical/validation';

export const useGetTrademarkImagesByFamilyIds = (ids: string[]) => {
  const { data, loading } = useGetTrademarkImagesByFamilyIdsQuery({
    fetchPolicy: 'no-cache',
    variables: { ids },
    skip: !ids.length,
  });

  return {
    imageList:
      data?.getTrademarkImagesByFamilyIds?.filter((d) => isDefined(d.image)) ??
      [],
    loading,
  };
};
