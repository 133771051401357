import classNames from 'classnames';
import styles from './index.module.scss';

interface IconStatusProps {
  success?: boolean;
  size?: number;
  style?: React.CSSProperties;
  className?: string;
}

const IconStatus = ({
  size = 12,
  success,
  style,
  className,
}: IconStatusProps) => {
  if (!className && success === undefined) {
    return null;
  }
  return (
    <div
      className={classNames(
        styles.iconStatus,
        className ? styles[className] : { [styles.success]: success },
      )}
      style={{
        ...style,
        height: size,
        width: size,
      }}
    />
  );
};

export default IconStatus;
