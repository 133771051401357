import ConnectedPage from 'business/user/helpers/connectedPage';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import Map from 'ui/map';
import Spacer from 'ui/spacer';
import Carousel from 'ui/carousel';
import HeaderTitles from 'ui/headerTitles';
import { isDefined } from 'technical/validation';
import DesignFamilyTable from 'business/resources/design/components/designFamilyTable';
import { useGetDesignImages } from 'business/resources/design/services/designImage.service';
import {
  useGetDesignFamilyInfos,
  useGetDesignFamilyMap,
} from 'business/resources/design/services/designFamily.service';
import { useDesignBreadcrumbs } from 'business/resources/design/services/design.service';

const MAX_CAROUSEL_IMAGE_COUNT = 4;

const DesignFamilyPage: React.VFC = () => {
  const { t } = useTranslation();
  const { id: familyId } = useParams<{ id: string }>();

  const { imageList, loading: imageLoading } = useGetDesignImages([familyId]);

  const { dataMap, loading } = useGetDesignFamilyMap(familyId);

  const { getFamilyBreadcrumbs } = useDesignBreadcrumbs();
  const designFamily = useGetDesignFamilyInfos(familyId);

  return (
    <ConnectedPage
      breadcrumbs={getFamilyBreadcrumbs(designFamily)}
      translationKey="designFamily"
    >
      <HeaderTitles
        title={designFamily?.title ?? ''}
        subtitle={t('pages.header.designFamily')}
      />
      <Carousel
        urls={imageList.map((i) => i.image).filter(isDefined)}
        loading={imageLoading}
        slidesToShow={Math.min(
          imageList.filter(isDefined).length,
          MAX_CAROUSEL_IMAGE_COUNT,
        )}
        className="carousel"
      />
      <Spacer direction="vertical" size="small" align="center">
        <DesignFamilyTable familyId={familyId} />

        <Map resourceType="design" isDataLoading={loading} data={dataMap} />
      </Spacer>
    </ConnectedPage>
  );
};

export default DesignFamilyPage;
