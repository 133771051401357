import {
  DesignWithImages,
  GetOfficialLinkFunction,
} from 'business/common/services/officialRegister/types';
import { Patent } from 'business/resources/patent/types';
import { Trademark } from 'business/resources/trademark/services/trademark.types';

type ResourcesWithOfficialLinks = Patent | DesignWithImages | Trademark;

export const useGetOfficialLinks = <T extends ResourcesWithOfficialLinks>(
  getOfficialLink: GetOfficialLinkFunction<T>,
  asset: T | null | undefined,
) => {
  if (!asset || !asset?.country?.code) {
    return null;
  }
  const link = getOfficialLink(asset);
  if (!link) {
    return null;
  }

  return Array.isArray(link) ? link : [link];
};
