import { Image, Tooltip } from 'antd';
import StatusBadge from 'business/common/components/statusBadge';
import { generatePathByAssetType } from 'business/common/services';
import { TFunction } from 'i18next';
import { Link } from 'react-router-dom';
import {
  getCountriesKey,
  getCountryKey,
} from 'technical/countriesTranslation/constants';
import { formatDate } from 'technical/date';
import { FAMILIES_TABLE_IMAGE_STYLE } from 'ui/imageDisplay/constants';
import Loader from 'ui/loader';

const designColumns = (t: TFunction) => ({
  designFamily: [
    {
      title: t('table.common.column.title'),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
    },
    {
      title: t('table.common.column.country'),
      sorter: true,
      dataIndex: 'countryFr',
      key: 'countryFr',
      render: (_: any, data: any) => {
        return (
          <Link
            data-test-id="titleRedirect"
            className="titleRedirect"
            to={generatePathByAssetType('design', data.id)}
          >
            {data[getCountryKey()]}
          </Link>
        );
      },
    },
    {
      title: t('table.common.column.depositNumber'),
      dataIndex: 'depositNumber',
      key: 'depositNumber',
      sorter: true,
    },
    {
      key: 'depositDate',
      title: t('table.common.column.depositDate'),
      dataIndex: 'depositDate',
      render: (_: any, data: any) => {
        if (data.depositDate) {
          return formatDate(new Date(data.depositDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.registrationNumber'),
      dataIndex: 'registrationNumber',
      key: 'registrationNumber',
      sorter: true,
    },
    {
      key: 'registrationDate',
      title: t('table.common.column.registrationDate'),
      dataIndex: 'registrationDate',
      render: (_: any, data: any) => {
        if (data.registrationDate) {
          return formatDate(new Date(data.registrationDate));
        }
      },
      sorter: true,
    },

    {
      title: t('table.common.column.clientRef'),
      dataIndex: 'clientRef',
      key: 'clientRef',
      sorter: true,
    },
    {
      title: t('table.common.column.clientRef2'),
      dataIndex: 'clientRef2',
      key: 'clientRef2',
      sorter: true,
    },
    {
      title: t('table.common.column.bdlRef'),
      dataIndex: 'bdlRef',
      key: 'bdlRef',
      sorter: true,
    },
    {
      title: t('table.common.column.misRef'),
      dataIndex: 'misRef',
      key: 'misRef',
      sorter: true,
    },
    {
      title: t('table.common.column.status'),
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (_: any, data: any) => {
        if (data.status) {
          return <StatusBadge status={data.status ?? ''} />;
        }
      },
    },
    {
      title: t('table.common.column.adjournedPublication'),
      dataIndex: 'adjournedPublication',
      key: 'adjournedPublication',
      sorter: false,
      render: (_: any, data: any) => {
        if (data.adjournedPublication) {
          return t('table.common.column.adjournedPublication');
        } else {
          return t('common.no');
        }
      },
    },
  ],
  designFamilies: [
    {
      title: t('table.common.column.holder'),
      dataIndex: 'holder',
      key: 'holder',
      sorter: true,
    },
    {
      title: t('table.common.column.title', { context: 'design' }),
      dataIndex: 'title',
      key: 'title',
      sorter: true,
      render: (_: any, record: any) => (
        <Link
          data-test-id="titleRedirect"
          className="titleRedirect"
          to={`/design/family/${record.id}`}
        >
          {record.title}
        </Link>
      ),
    },
    {
      title: 'image',
      dataIndex: 'image',
      key: 'image',
      sorter: false,
      render: (_: any, record: any) => {
        if (record.image === 'loading') {
          return <Loader />;
        }
        if (record.image) {
          return (
            <Image
              src={record.image}
              style={{ ...FAMILIES_TABLE_IMAGE_STYLE, objectFit: 'contain' }}
            />
          );
        }
      },
    },
    {
      title: t('table.common.column.countries'),
      sorter: true,
      dataIndex: 'countriesFr',
      key: 'countriesFr',
      render: (_: any, data: any) => {
        return (
          <Tooltip title={data[getCountriesKey()]}>
            {data[getCountriesKey()]}
          </Tooltip>
        );
      },
    },
    {
      title: t('table.common.column.firstDepositNumber'),
      dataIndex: 'firstDepositNumber',
      key: 'firstDepositNumber',
      sorter: true,
    },
    {
      title: t('table.common.column.firstDepositDate'),
      dataIndex: 'firstDepositDate',
      key: 'firstDepositDate',
      render: (_: any, data: any) => {
        if (data.firstDepositDate) {
          return formatDate(new Date(data.firstDepositDate));
        }
      },
      sorter: true,
    },
    {
      title: t('table.common.column.bdlRef'),
      dataIndex: 'bdlRef',
      key: 'bdlRef',
      sorter: true,
    },
    {
      title: t('table.common.column.bdlOfficerName'),
      dataIndex: 'bdlOfficerName',
      key: 'bdlOfficerName',
      sorter: true,
    },
  ],
});

export default designColumns;
