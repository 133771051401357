import Spacer from 'ui/spacer';
import Flex from 'ui/flex';
import Table from 'ui/table';
import { OsrFilterState } from 'business/resources/osr/services/types';
import { useGetDesignOsrData } from 'business/resources/osr/services/useGetDesignOsrData';
import { DESIGN_ABANDONMENT_COLUMNS } from 'business/resources/osr/components/common/osrTableColumns';
import { endOfDay, endOfYear, startOfYear } from 'date-fns';
import { RangePicker } from 'ui/date/datePicker';
import { IFiltersActions } from 'business/resources/osr/services/useOsrFilters';
import TagResults from 'ui/tag/tagResults';

interface IAbandonmentListProps {
  filters: OsrFilterState;
  actions: IFiltersActions;
}

const AbandonmentList = (props: IAbandonmentListProps) => {
  const { data: abandonmentList, tableProps: abandonmentListTableProps } =
    useGetDesignOsrData(
      { ...props.filters, eventStatus: props.filters.eventStatus },
      'ABANDONMENT',
    );

  const tableProps = {
    ...abandonmentListTableProps,
    pagination: {
      ...abandonmentListTableProps.pagination,
      pageSizeOptions: ['10', '30', '100', '500', '1000'],
    },
  };

  return (
    <Spacer direction="vertical" size="small">
      <Flex alignItems="center" justify="space-between">
        <Flex column style={{ width: '33%' }}>
          <RangePicker
            allowClear
            inputReadOnly={true}
            defaultValue={[startOfYear(new Date()), endOfYear(new Date())]}
            onChange={(e) => {
              props.actions.onChangeRangeDate(
                e && e[0] ? new Date(endOfDay(e[0])) : e?.[0] ?? null,
                e && e[1] ? new Date(endOfDay(e[1])) : e?.[1] ?? null,
              );
            }}
          />
        </Flex>
      </Flex>

      {/* <Flex>
        <Button
          loading={loading}
          type="primary"
          onClick={() => generateExcel('RENEWS')}
        >
          Exporter les Renouvellements
        </Button>
      </Flex> */}

      <Spacer direction="vertical" size="small">
        <Table
          name="abandonmentTable"
          header={
            <TagResults
              total={abandonmentListTableProps.pagination?.total ?? 0}
              dataTestId="abandonmentList"
            />
          }
          defaultColumns={DESIGN_ABANDONMENT_COLUMNS()}
          dataSource={abandonmentList}
          {...tableProps}
        />
      </Spacer>
    </Spacer>
  );
};

export default AbandonmentList;
