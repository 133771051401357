import { getCountryLabel } from 'technical/countriesTranslation';

import ConnectedPage from 'business/user/helpers/connectedPage';
import { useParams } from 'react-router';
import ErrorOverlay from 'ui/errorOverlay';
import HeaderTitles from 'ui/headerTitles';
import Timeline from 'ui/timeline';
import ResourceNotFound from 'business/resources/shared/components/resourceNotFound';
import { resourceExists } from 'business/common/services';
import getTrademarkDetails from 'business/resources/trademark/services/getTrademarkDetails';
import Spacer from 'ui/spacer';
import { getTrademarkOfficialLink } from 'business/common/services/officialRegister/getTrademarkOfficialLink';
import { Trademark } from 'business/resources/trademark/services/trademark.types';
import { useGetOfficialLinks } from 'business/resources/shared/services/links/useGetOfficialLink';
import ResourcePanel from 'business/resources/shared/components/resourcePanel/resourcePanel';
import OfficalLinkButton from 'business/resources/shared/components/officialLinkButton';
import ResourceLinks from 'business/common/components/resourceLinks';
import { useTranslation } from 'react-i18next';
import Routes from 'business/router/routes';
import getTrademarkTabs from 'business/resources/trademark/services/getTrademarkTabs';
import { useResourceBreadcrumbs } from 'business/common/services/breadcrumbs';
import {
  useGetTrademark,
  useGetTrademarkEvents,
  useGetTrademarkLink,
} from 'business/resources/trademark/services/trademark.service';
import { useGetTrademarkImagesByFamilyIds } from 'business/resources/trademark/services/trademarkImage.service';
import { isDefined } from 'technical/validation';
import Carousel from 'ui/carousel';

const TrademarkPage = () => {
  const { t } = useTranslation();
  const { id } = useParams<{ id: string }>();
  const trademarkId = Number(id);

  const { trademark, trademarkCertificate, timeline, trademarkDataState } =
    useGetTrademark(trademarkId);
  const { imageList, loading: loadingImage } = useGetTrademarkImagesByFamilyIds(
    [trademark?.familyId].filter(isDefined),
  );
  const { data: events, tableProps: eventTableProps } =
    useGetTrademarkEvents(trademarkId);

  const { getResourceBreadcrumbs } = useResourceBreadcrumbs({
    type: 'trademark',
    familyRoute: Routes.TrademarkFamily,
    rootRoute: Routes.Trademark,
  });
  const { trademarkLinks } = useGetTrademarkLink(trademark);
  const links = useGetOfficialLinks<Trademark>(
    getTrademarkOfficialLink,
    trademark,
  );

  return (
    <ResourceNotFound
      resourceExists={resourceExists(trademark, trademarkDataState)}
    >
      <ConnectedPage
        breadcrumbs={getResourceBreadcrumbs(trademark)}
        translationKey="trademarkDetails"
      >
        <Spacer direction="vertical" size="middle">
          <ErrorOverlay {...trademarkDataState}>
            <Spacer direction="vertical" size="xsmall">
              <HeaderTitles
                title={getCountryLabel(trademark?.country)}
                subtitle={trademark?.title ?? ''}
                dataTestId="trademarkPage"
                family={{
                  id: trademark?.familyId,
                  route: Routes.TrademarkFamily,
                }}
              />
              <ResourceLinks resource={trademark} />

              <Timeline events={timeline} />
            </Spacer>

            <ResourcePanel
              description={{
                data: trademark,
                panels: getTrademarkDetails(trademarkLinks, t, trademark),
              }}
              resource="trademark"
              resourceId={trademarkId}
              image={
                <Carousel
                  urls={imageList.map((i) => i.image).filter(isDefined)}
                  loading={loadingImage}
                  slidesToShow={Math.min(imageList.filter(isDefined).length, 1)}
                  className="carousel"
                />
              }
              additionalTabs={getTrademarkTabs({
                loading: trademarkDataState.loading,
                events: events,
                eventTableProps: eventTableProps,
                trademark: trademark,
                certificates: trademarkCertificate,
              })}
              additionalActions={links && <OfficalLinkButton link={links[0]} />}
            />
          </ErrorOverlay>
        </Spacer>
      </ConnectedPage>
    </ResourceNotFound>
  );
};

export default TrademarkPage;
