import { MimeType } from './constants';
import { RcFile } from './types';

export const uploadObjectAsync = async (
  file: RcFile | File,
  signedUrl: string,
) => {
  const headers = new Headers();
  headers.append('Content-Type', `${file.type}`);
  headers.append('Content-Length', `${file.size}`);

  const options = {
    method: 'PUT',
    body: file,
    headers,
  };

  return fetch(signedUrl, options);
};

export function formatBytes(bytes: number, decimals = 2) {
  if (!+bytes) {
    return '0';
  }

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['o', 'Ko', 'Mo', 'Go', 'To'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / k ** i).toFixed(dm))} ${sizes[i]}`;
}

export function convertBinarytoBlob(content: string, contentType?: MimeType) {
  const sliceSize = 512;
  const byteArrays = [];
  for (let offset = 0; offset < content.length; offset += sliceSize) {
    const slice = content.slice(offset, offset + sliceSize);
    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i += 1) {
      byteNumbers[i] = slice.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }
  return new Blob(byteArrays, {
    type: contentType,
  }); // statement which creates the blob
}

export const downloadFile = (
  blob: Blob,
  fileName: string,
  openInNewTab?: boolean,
) => {
  const fileLink = document.createElement('a');
  fileLink.href = URL.createObjectURL(blob);
  if (openInNewTab) {
    fileLink.target = '_blank';
  } else {
    fileLink.download = fileName;
  }
  fileLink.click();
};

export const convertToBinaryAndDownloadFile = (
  content: string,
  contentType: MimeType,
  fileName: string,
  openInNewTab?: boolean,
) => {
  return downloadFile(
    convertBinarytoBlob(content, contentType),
    fileName,
    openInNewTab,
  );
};

export const convertAndDownloadFile = async (
  fileName: string,
  document?: string | null,
) => {
  if (!document) {
    throw new Error('Cannot convert: document not defined');
  }
  const blob = await (await fetch(document)).blob();
  return downloadFile(blob, fileName);
};
