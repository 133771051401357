import { notification } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { format } from 'date-fns';
import {
  DownloadPatentFamiliesDto,
  useDownloadPatentFamiliesLazyQuery,
  useGetPatentFamiliesQuery,
} from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import errorReporting from 'technical/error-reporting';
import { convertAndDownloadFile } from 'technical/fileManagement';
import logger from 'technical/logger';

export const useGetPatentFamilies = (variables: {
  limit: number;
  offset: number;
  search?: string;
  isEffective?: boolean;
  isOwn?: boolean;
  orderBy?: Record<string, 'asc' | 'desc'> | null;
}) => {
  const { data, error, loading } = useGetPatentFamiliesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        where: {
          isEffective: variables.isEffective,
          isOwn: variables.isOwn,
          search: variables.search,
        },
        limit: variables.limit,
        offset: variables.offset,
        orderBy: variables.orderBy
          ? variables.orderBy
          : { firstDepositDate: 'desc' },
      },
    },
  });

  return {
    data: data?.getPatentFamilies?.resources ?? [],
    total: data?.getPatentFamilies?.count ?? 0,
    error,
    loading,
  };
};

export const useDownloadPatentFamilies = (
  initialColumns: ColumnsType,
  visibleColumns: string[],
  filters: DownloadPatentFamiliesDto['filters'],
) => {
  const { t } = useTranslation();
  const [downloadPatentFamilies, { loading }] =
    useDownloadPatentFamiliesLazyQuery();

  const columns = initialColumns
    .filter((c) => {
      return visibleColumns.includes(c.key?.toString() ?? '');
    })
    .map((c) => ({
      key: c.key?.toString() ?? '',
      value: c.title?.toString() ?? '',
    }));

  const download = async () => {
    try {
      const { data } = await downloadPatentFamilies({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            columns: columns,
            filters: filters,
          },
        },
      });
      return await convertAndDownloadFile(
        `export_patentFamilies_${format(new Date(), 'yyyyMMdd_HHmm')}`,
        data?.downloadPatentFamilies?.file,
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error({
        err,
        message: 'Failed to download patent families',
      });
      notification.error({
        message: t('errors.error', { context: 'xlsxDownload' }),
      });
    }
  };
  return { download, loading };
};
