import { useTranslation } from 'react-i18next';

const dateFormats: Record<string, string> = {
  'fr-FR': 'dd/MM/y',
  en: 'MM/dd/yyyy',
};

export const useDateformat = () => {
  const { i18n } = useTranslation();
  return dateFormats[i18n.language] ?? dateFormats.en;
};
