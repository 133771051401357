import {
  DownloadTrademarkFamiliesDto,
  useDownloadTrademarkFamiliesLazyQuery,
  useGetTrademarkFamiliesQuery,
} from 'generated/graphql';
import { useGetTrademarkImagesByFamilyIds } from './trademarkImage.service';
import { isDefined } from 'technical/validation';
import { useTranslation } from 'react-i18next';
import { ColumnsType } from 'antd/es/table';
import { convertAndDownloadFile } from 'technical/fileManagement';
import errorReporting from 'technical/error-reporting';
import logger from 'technical/logger';
import { notification } from 'antd';
import { format } from 'date-fns';

export const useGetTrademarkFamilies = (variables: {
  limit: number;
  offset: number;
  search?: string;
  isEffective?: boolean;
  isOwn?: boolean;
  orderBy?: Record<string, 'asc' | 'desc'> | null;
}) => {
  const { data, error, loading } = useGetTrademarkFamiliesQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        where: {
          isEffective: variables.isEffective,
          isOwn: variables.isOwn,
          search: variables.search,
        },
        limit: variables.limit,
        offset: variables.offset,
        orderBy: variables.orderBy
          ? variables.orderBy
          : { firstDepositDate: 'desc' },
      },
    },
  });
  const trademarkData = data?.getTrademarkFamilies?.resources ?? [];

  const { imageList, loading: imageLoading } = useGetTrademarkImagesByFamilyIds(
    trademarkData.map((d) => d.id).filter(isDefined),
  );

  const trademarkWithImages = trademarkData.map((d) => {
    const image = imageList.filter((i) => i.familyId === d.id);
    return {
      ...d,
      image: imageLoading ? 'loading' : image[0]?.image,
    };
  });

  return {
    data: trademarkData,
    trademarkWithImages,
    total: data?.getTrademarkFamilies?.count ?? 0,
    error,
    loading,
  };
};

export const useDownloadTrademarkFamilies = (
  initialColumns: ColumnsType,
  visibleColumns: string[],
  filters: DownloadTrademarkFamiliesDto['filters'],
) => {
  const { t } = useTranslation();
  const [downloadTrademarkFamilies, { loading }] =
    useDownloadTrademarkFamiliesLazyQuery();

  const columns = initialColumns
    .filter((c) => {
      return visibleColumns.includes(c.key?.toString() ?? '');
    })
    .map((c) => ({
      key: c.key?.toString() ?? '',
      value: c.title?.toString() ?? '',
    }));

  const download = async () => {
    try {
      const { data } = await downloadTrademarkFamilies({
        fetchPolicy: 'no-cache',
        variables: {
          data: {
            columns: columns,
            filters: filters,
          },
        },
      });
      return await convertAndDownloadFile(
        `export_trademarkFamilies_${format(new Date(), 'yyyyMMdd_HHmm')}`,
        data?.downloadTrademarkFamilies?.file,
      );
    } catch (err) {
      errorReporting.error(err);
      logger.error({
        err,
        message: 'Failed to download patent families',
      });
      notification.error({
        message: t('errors.error', { context: 'xlsxDownload' }),
      });
    }
  };
  return { download, loading };
};
