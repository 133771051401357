import { useTranslation } from 'react-i18next';
import ProductAndServices from 'business/resources/trademark/components/classes';
import ResourceCommentList from 'business/resources/shared/components/comments/resourceCommentList';
import { GeneratedTableProps } from 'technical/hooks/useQueryForTable';
import { GetTrademarkQuery } from 'generated/graphql';
import EventTable from 'business/resources/shared/components/event/eventTable';
import { Event } from 'business/resources/events/services/types';
import TrademarkAffidavit from 'business/resources/trademark/components/trademarkAffidavit';

interface IGetTrademarkTabs {
  loading: boolean;
  events: Event[];
  trademark: GetTrademarkQuery['trademark_by_pk'];
  eventTableProps: GeneratedTableProps<Event>;
  certificates: any[];
}

const getTrademarkTabs = (data: IGetTrademarkTabs) => {
  // const [getTrademarkCertificate] =
  //   useGetTrademarkCertificateSignedUrlLazyQuery();
  const { t } = useTranslation();
  const tabs = [
    {
      key: 'classes',
      label: t('pages.trademark.productAndServices'),
      children: (
        <ProductAndServices
          loading={data.loading}
          classes={data.trademark?.trademarkClasses ?? []}
        />
      ),
    },
    {
      key: 'events',
      label: t('table.title.event'),
      children: (
        <EventTable
          editButtonEnabled={false}
          searchBarEnabled={false}
          displayAnnuityAction={false}
          dataSource={data.events}
          {...data.eventTableProps}
        />
      ),
    },

    {
      key: 'comments',
      label: t('notes.title'),
      children: (
        <ResourceCommentList
          id={data.trademark?.id ?? 0}
          resourceType="trademark"
        />
      ),
    },
  ];

  const afuCountries = ['US', 'MX', 'PH', 'KH', 'AR', 'MZ', 'PE', 'JE', 'PR'];

  if (afuCountries.includes(data.trademark?.country?.code ?? '')) {
    tabs.splice(2, 0, {
      key: 'afu',
      label: "Preuves d'usage",
      children: <TrademarkAffidavit trademarkId={data.trademark?.id ?? 0} />,
    });
  }

  // if (data.certificates) {
  //   tabs.splice(1, 0, {
  //     key: 'certificate',
  //     label: 'Certificats',
  //     children: (
  //       <List
  //         dataSource={data.certificates ?? []}
  //         renderItem={({ id, type }) => (
  //           <List.Item
  //             actions={[
  //               <Button
  //                 onClick={async () => {
  //                   const { data: dataCertificate } =
  //                     await getTrademarkCertificate({
  //                       variables: {
  //                         certificateId: id,
  //                       },
  //                     });
  //                   const url =
  //                     dataCertificate?.getTrademarkCertificateSignedUrl;
  //                   if (!url) {
  //                     return;
  //                   }
  //                   window.open(url);
  //                 }}
  //                 type="primary"
  //               >
  //                 <FilePdfOutlined />
  //                 {t('common.openFile')}
  //               </Button>,
  //             ]}
  //           >
  //             <span>{type}</span>
  //           </List.Item>
  //         )}
  //       />
  //     ),
  //   });
  // }

  return tabs;
};

export default getTrademarkTabs;
