import { Trademark } from 'business/resources/trademark/services/trademark.types';
import {
  onlyNumbers,
  pad,
  removeSpaces,
  takeBeforeDot,
} from 'technical/format/string';
import { isDefined } from 'technical/validation';
import { links } from './constants';
import {
  getOfficialLink,
  isProtectionException,
  isProtectionMadridProtocol,
  isProtectionNational,
  replaceLink,
} from './helpers';
import { OfficialLinkMethods } from './types';

const TRADEMARK_OFFICIAL_LINKS: OfficialLinkMethods<Trademark> = {
  EM: getOfficialLink(
    ({ depositNumber, protection }: Trademark) =>
      isDefined(depositNumber) && !isProtectionException(protection),
  )('depositNumber', (depositNumber, td) =>
    isProtectionException(td?.protection)
      ? `W${pad(8)(depositNumber)}`
      : depositNumber,
  )(links.trademark.EM),

  FR: getOfficialLink(
    ({ lastRegistrationDate, registrationNumber }: Trademark) =>
      isDefined(lastRegistrationDate) && isDefined(registrationNumber),
  )('registrationNumber', removeSpaces, takeBeforeDot, (registrationNumber) =>
    registrationNumber.length === 9
      ? registrationNumber.substring(2)
      : registrationNumber,
  )(links.trademark.FR),

  WO: getOfficialLink(
    ({ lastRegistrationDate, registrationNumber }: Trademark) =>
      isDefined(lastRegistrationDate) &&
      isDefined(registrationNumber) &&
      !Number.isNaN(+removeSpaces(registrationNumber)),
  )(
    'registrationNumber',
    removeSpaces,
    pad(7),
  )(links.trademark.WO),

  US: getOfficialLink(({ depositNumber }: Trademark) =>
    isDefined(depositNumber),
  )(
    'depositNumber',
    onlyNumbers,
    pad(7),
  )(({ protection }) => {
    if (isProtectionNational(protection)) {
      return links.trademark.US_NAT;
    }
    if (isProtectionMadridProtocol(protection)) {
      return links.trademark.US_MP;
    }
    return null;
  }),

  // Due to weird conditions and formatting, we need to use a traditionnal function here
  // instead of getOfficialLink
  GB: ({ depositNumber, protection }: Trademark) => {
    if (isDefined(depositNumber)) {
      if (isProtectionNational(protection)) {
        return replaceLink(
          links.trademark.GB_NAT,
          pad(11)(removeSpaces(depositNumber.replace(/^(UK)/, ''))),
        );
      }
      if (isProtectionMadridProtocol(protection)) {
        return replaceLink(links.trademark.GB_MP, pad(13)(depositNumber));
      }
    }
    return null;
  },
};

export const getTrademarkOfficialLink = (trademark: Trademark) => {
  if (!trademark.country?.code) {
    return null;
  }
  const method = TRADEMARK_OFFICIAL_LINKS[trademark.country.code];
  return method ? method(trademark) : null;
};
