import React from 'react';
import ConnectedPage from 'business/user/helpers/connectedPage';
import PatentFamiliesTable from 'business/resources/patent/components/patentFamiliesTable';
import { useTranslation } from 'react-i18next';
import Spacer from 'ui/spacer';

const PatentFamiliesPage: React.FC = () => {
  const { t } = useTranslation();

  return (
    <ConnectedPage translationKey="patents">
      <Spacer direction="vertical" size="small">
        <h1 className="headerTitle">{t('pages.header.patentFamilies')}</h1>
        <PatentFamiliesTable />
      </Spacer>
    </ConnectedPage>
  );
};

export default PatentFamiliesPage;
